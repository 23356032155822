import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class NumberMatchesService {

  constructor(private db: AngularFireDatabase) { }

  getNumAllMatches(patch: string) {
    return this.db.object(`${patch.replace(/\./g, '*')}/MP`).valueChanges();
  }

  getNumMatchesMode(patch: string, mode: string) {
    return this.db.object(`${patch.replace(/\./g, '*')}/MP${mode}`).valueChanges();
  }

  getNumPlays(patch: string, rank: number) {
    return this.db.object(`${patch.replace(/\./g, '*')}/M${rank}`).valueChanges();
  }

  getNumMatchesModePerRank(patch: string, mode: string, rank: number) {
    return this.db.object(`${patch.replace(/\./g, '*')}/M${mode}${rank}`).valueChanges();
  }

}
