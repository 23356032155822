import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdblockService {

  constructor() { }

  // Determines if the user is likely using an ad block extension
  checkAdBlocker() {
    try {
      return fetch(
        new Request("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", {
          method: 'HEAD',
          mode: 'no-cors'
        })).then(response => {
          // Google Ads request succeeded, so likely no ad blocker
          return false;
        }).catch(e => {
          // Request failed, likely due to ad blocker
          return true;
        });
    } catch (error) {
      // fetch API error; possible fetch not supported (old browser)
      // Marking as a blocker since there was an error and so
      // we can prevent continued requests when this function is run
      return true;
    }
  }
}
