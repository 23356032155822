import { Component, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TopGodsService } from '../../services/top-gods.service';
import { PatchService } from '../../services/patch.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GodsService } from '../../services/gods.service';
import { RankService } from '../../services/rank.service';
import { NumberMatchesService } from '../../services/number-matches.service';
import { SeoService } from '../../services/seo.service';
import { Subscription } from 'rxjs';

declare var $: any;

export interface ModeDetailsGodElement {
  I: string;
  N: string;
  S: number;
  '%': number;
  B: number;
  PB: number;
  KDA: number;
  DA: number;
  R: string;
  P: string;
}

export interface ModeDetailsCompositionElement {
  N: string[];
  S: number;
  '%': number;
}

@Component({
  selector: 'app-mode-details',
  templateUrl: './mode-details.component.html',
  styleUrls: ['./mode-details.component.css']
})
export class ModeDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('godsTable', {read: MatSort}) godsSort: MatSort;
  @ViewChild('compositionTable', {read: MatSort}) compositionSort: MatSort;

  @ViewChild('godsPaginator') godsPaginator: MatPaginator;
  @ViewChild('compositionPaginator') compositionPaginator: MatPaginator;

  dataGods: MatTableDataSource<ModeDetailsGodElement>;
  dataCompositions: MatTableDataSource<ModeDetailsCompositionElement>;

  displayedColumnsGods = ['N', '%', 'S', 'B', 'PB', 'KDA', 'DA'];
  displayedColumnsComposition = ['N', '%', 'S'];

  modeName: string;
  mostGods: {};
  mostCompositions = 0;
  activeRole = 'all roles';
  activePantheon = 'all pantheons';
  allGodsArr: ModeDetailsGodElement[] = [];
  allCompositionsArr: ModeDetailsCompositionElement[] = [];
  shownCompositionsArr: ModeDetailsCompositionElement[] = [];
  roles: string[] = ['assassin', 'guardian', 'hunter', 'mage', 'warrior'];
  ranks: string[] = ['bronze', 'silver', 'gold', 'platinum', 'diamond', 'master', 'grandmaster', 'Gold–', 'Platinum+', 'Diamond+'];
  compositionChoice: any[];
  selectedRank = 0;
  selectedRankImage = 0;
  numMatches: number;
  numBans: number;

  loadingGods = true;
  loadingCompositions = true;

  subscriptions: Subscription[] = [];
  paramsSubscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private topGodsService: TopGodsService,
    private godsService: GodsService,
    private patchService: PatchService,
    private rankService: RankService,
    private numberMatchesService: NumberMatchesService,
    private changeDetectorRef: ChangeDetectorRef,
    private seoService: SeoService
  ) {}

  ngOnInit() {
    this.mostGods = {
      matches: 0,
      bans: 0,
      pickban: 0,
      kda: 0,
      damage: 0,
      mitigated: 0
    };

    this.paramsSubscriptions.push(this.route.paramMap.subscribe((params: ParamMap) => {
      const queues = ['conquest', 'joust', 'duel'];
      this.modeName = params.get('name');
      if (!queues.includes(this.modeName)) {
        this.router.navigate(['']);
      }

      this.seoService.setSeo('modeDetails', this.modeName);

      if (this.modeName === 'joust') {
        this.compositionChoice = [['noSelection', 0], ['noSelection', 1], ['noSelection', 2]];
      } else if (this.modeName === 'duel') {
        this.compositionChoice = [['noSelection', 0]];
      } else {
        this.compositionChoice = [['noSelection', 0], ['noSelection', 1], ['noSelection', 2], ['noSelection', 3], ['noSelection', 4]];
      }

      this.subscriptions.push(this.patchService.getSelectedPatch().subscribe(sp => {
        if (sp == null) {
          return;
        }

        this.subscriptions.slice(1).forEach(subscription => subscription.unsubscribe());

        this.loadingGods = true;
        this.loadingCompositions = true;

        this.activeRole = 'all roles';
        this.activePantheon = 'all pantheons';
  
        this.subscriptions.push(this.rankService.getSelectedRank().subscribe((rank: number) => {
          this.selectedRank = rank;
          this.selectedRankImage = rank === 8 ? 3 : rank === 9 ? 4 : rank === 10 ? 5 : rank;

          this.loadingGods = true;
          this.loadingCompositions = true;

          this.subscriptions.push(this.numberMatchesService.getNumMatchesModePerRank(sp, this.modeName, rank).subscribe((numMatches: number) => {
            this.numMatches = numMatches;
          }));

          this.subscriptions.push(this.topGodsService.getGods(this.modeName, sp, rank).subscribe((topG: any) => {
            if (topG == null) {
              return;
            }

            this.mostGods = {
              matches: 0,
              bans: 0,
              pickban: 0,
              kda: 0,
              damage: 0,
              mitigated: 0
            };

            this.loadingGods = false;

            this.subscriptions.push(this.godsService.getGods().subscribe(gods => {
              if (gods == null) {
                return;
              }

              let arr: ModeDetailsGodElement[] = [];
              for (const g of Object.keys(topG)) {

                if (gods[g]) {

                  let kda = +((+topG[g]['A'] / 2 + +topG[g]['K']) / +topG[g]['D']).toFixed(2);
                  if (isNaN(kda)) {
                    kda = 0;
                  }
                  let winP = +(100 * topG[g]['W'] / topG[g]['S']).toFixed(2);
                  if (isNaN(winP)) {
                    winP = 0;
                  }

                  arr.push({
                    I: topG[g]['I'],
                    N: g,
                    S: topG[g]['S'],
                    '%': winP,
                    B: topG[g]['B'],
                    PB: topG[g]['S'] + topG[g]['B'],
                    KDA: kda,
                    DA: topG[g]['DA'],
                    R: gods[g]['R'],
                    P: gods[g]['P']
                  });

                  if (topG[g]['S'] > this.mostGods['matches']) {
                    this.mostGods['matches'] = topG[g]['S'];
                  }
                  if (topG[g]['B'] > this.mostGods['bans']) {
                    this.mostGods['bans'] = topG[g]['B'];
                  }
                  if (topG[g]['S'] + topG[g]['B'] > this.mostGods['pickban']) {
                    this.mostGods['pickban'] = topG[g]['S'] + topG[g]['B'];
                  }
                  if (kda > this.mostGods['kda'] && isFinite(kda)) {
                    this.mostGods['kda'] = kda;
                  }
                  if (topG[g]['DA'] > this.mostGods['damage']) {
                    this.mostGods['damage'] = topG[g]['DA'];
                  }
                  if (topG[g]['M'] > this.mostGods['mitigated']) {
                    this.mostGods['mitigated'] = topG[g]['M'];
                  }
                }
              }

              this.allGodsArr = arr;

              setTimeout(() => {
                this.dataGods = new MatTableDataSource(arr);
                this.dataGods.sort = this.godsSort;
                this.dataGods.paginator = this.godsPaginator;
                if (!this.changeDetectorRef['destroyed']) {
                  this.changeDetectorRef.detectChanges();
                }
              }, 1);
            }));
          }));

          this.subscriptions.push(this.topGodsService.getCompositions(this.modeName, sp, rank).subscribe((comp: any) => {
            if (comp == null) {
              return;
            }

            this.mostCompositions = 0;

            let arrC: ModeDetailsCompositionElement[] = [];
            for (const c of Object.keys(comp)) {

              const compositonRoles = [];
              for (let i = 0; i < c.length; i++) {
                if (c.charAt(i) === 'A') {
                  compositonRoles.push('assassin');
                } else if (c.charAt(i) === 'G') {
                  compositonRoles.push('guardian');
                } else if (c.charAt(i) === 'H') {
                  compositonRoles.push('hunter');
                } else if (c.charAt(i) === 'M') {
                  compositonRoles.push('mage');
                } else if (c.charAt(i) === 'W') {
                  compositonRoles.push('warrior');
                }
              }

              arrC.push({
                N: compositonRoles,
                S: comp[c]['S'],
                '%': comp[c]['%']
              });

              if (comp[c]['S'] > this.mostCompositions) {
                this.mostCompositions = comp[c]['S'];
              }
            }

            this.allCompositionsArr = arrC;
            this.shownCompositionsArr = arrC;

            setTimeout(() => {
              this.dataCompositions = new MatTableDataSource(arrC);
              this.dataCompositions.sort = this.compositionSort;
              this.dataCompositions.paginator = this.compositionPaginator;
              if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
              }
            }, 1);

            this.loadingCompositions = false;
          }));
        }));
      }));
    }));
  }

  ngOnDestroy() {
    this.paramsSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onPaginatorClickGods(event) {
    document.getElementById('scrollGods').scrollIntoView();
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  imageURLRefactor(str: string) {
    return str.replace('\'', '').replace(/\s+/g, '-').toLowerCase();
  }

  search(role: string, pantheon: string) {

    let arr = this.allGodsArr;

    if (!pantheon) {
      $('#' + this.activeRole).removeClass('active');
      $('#' + role).addClass('active');
      this.activeRole = role;
    } else if (!role) {
      $('#' + this.activePantheon).removeClass('active');
      $('#' + pantheon).addClass('active');
      this.activePantheon = pantheon;
    }

    if (this.activeRole !== 'all roles') {
      arr = arr.filter(res => {
        return res.R.toLocaleLowerCase().match(this.activeRole);
      });
    }

    if (this.activePantheon !== 'all pantheons'){
      arr = arr.filter(res => {
        return res.P.toLocaleLowerCase().match(this.activePantheon);
      });
    }

    this.dataGods = new MatTableDataSource(arr);
    this.dataGods.sort = this.godsSort;
    this.dataGods.paginator = this.godsPaginator;
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  buildComposition(role: string, id: number) {
    this.compositionChoice[id][0] = role;
    const counts = {};
    this.compositionChoice.forEach(x => counts[x[0]] = (counts[x[0]] || 0) + 1);

    if ((this.modeName === 'joust' && counts['noSelection'] === 3) || (this.modeName === 'duel' && counts['noSelection'] === 1) || counts['noSelection'] === 5) {
      this.shownCompositionsArr = this.allCompositionsArr;
    } else {
      let i = 0;
      for (const c in counts) {
        if (c !== 'noSelection') {
          if (i === 0) {
            this.shownCompositionsArr = this.allCompositionsArr.filter(res => {
              return this.checkArrayContains(res.N, c, counts[c]);
            });
          } else {
            this.shownCompositionsArr = this.shownCompositionsArr.filter(res => {
              return this.checkArrayContains(res.N, c, counts[c]);
            });
          }
          i++;
        }
      }
    }

    this.dataCompositions = new MatTableDataSource(this.shownCompositionsArr);
    this.dataCompositions.sort = this.compositionSort;
    this.dataCompositions.paginator = this.compositionPaginator;
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  // check if array contains "num" of "str"
  checkArrayContains(arr: any[], str: string, num: number) {
    for (const x of arr) {
      if (x === str) {
        if (num > 1) {
          num--;
        } else {
          return true;
        }
      }
    }
    return false;
  }

}
