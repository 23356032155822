import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.css']
})
export class Page404Component implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.title.setTitle("Ranked Smite - Smite Stats");
    this.meta.updateTag({name: "description", content: "Latest stats for all Smite ranked modes: Conquest, Joust and Duel. Get recommended items to buy per mode. God stats include win rate, recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Sort Gods by win rate and see which Gods are current top performers. See which God scores the most pentakills."});
    this.meta.updateTag({name: "keywords", content: "smite stats,ranked smite,smite items,smite gods,conquest stats,joust stats,duel stats,statistics,stats,conquest,joust,duel,ranked,win rate,god counters"});
    this.meta.updateTag({property: "og:title", content: "Ranked Smite - Smite Stats"});
    this.meta.updateTag({property: "og:description", content: "Latest stats for all Smite ranked modes: Conquest, Joust and Duel. Get recommended items to buy per mode. God stats include win rate, recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Sort Gods by win rate and see which Gods are current top performers. See which God scores the most pentakills."});
    this.meta.updateTag({property: "twitter:title", content: "Ranked Smite - Smite Stats"});
    this.meta.updateTag({property: "twitter:description", content: "Latest stats for all Smite ranked modes: Conquest, Joust and Duel. Get recommended items to buy per mode. God stats include win rate, recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Sort Gods by win rate and see which Gods are current top performers. See which God scores the most pentakills."});
  }

}
