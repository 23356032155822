import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { GodDetailsService } from '../../services/god-details.service';
import { PatchService } from '../../services/patch.service';
import { Chart } from 'chart.js';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { RankService } from '../../services/rank.service';
import { NumberMatchesService } from '../../services/number-matches.service';
import { Skin } from 'src/models/skin.model';
import { GodDetailsChange } from 'src/models/godDetailsChange.model';
import { GodDetails } from 'src/models/godDetails.model';
import { GodDetailsPatchUnrelated } from 'src/models/GodDetailsPatchUnrelated.model';
import { SeoService } from '../../services/seo.service';
import { Item } from 'src/models/item.model';
import { God } from 'src/models/god.model';
import { GodsService } from 'src/app/services/gods.service';

declare var $: any;

@Component({
  selector: 'app-god-details',
  templateUrl: './god-details.component.html',
  styleUrls: ['./god-details.component.css']
})
export class GodDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('itemsTable', {read: MatSort}) itemsSort: MatSort;
  @ViewChild('activesTable', {read: MatSort}) activesSort: MatSort;
  @ViewChild('goodWithTable', {read: MatSort}) goodWithSort: MatSort;
  @ViewChild('goodAgainstTable', {read: MatSort}) goodAgainstSort: MatSort;

  @ViewChild('itemsPaginator') itemsPaginator: MatPaginator;
  @ViewChild('activesPaginator') activesPaginator: MatPaginator;
  @ViewChild('goodWithPaginator') goodWithPaginator: MatPaginator;
  @ViewChild('goodAgainstPaginator') goodAgainstPaginator: MatPaginator;

  dataItems: MatTableDataSource<{}>;
  dataActives: MatTableDataSource<{}>;
  dataGoodWith: MatTableDataSource<{}>;
  dataGoodAgainst: MatTableDataSource<{}>;

  mostMatchesItems = 0;
  mostMatchesActives = 0;
  mostMatchesGoodWith = 0;
  mostMatchesGoodAgainst = 0;

  displayedColumns = ['N', '%', 'S'];
  ranks: string[] = ['All ranks', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Master', 'Grandmaster', 'Gold–', 'Platinum+', 'Diamond+'];
  queues: string[] = ['conquest', 'joust', 'duel'];

  godIcons: {};
  dataPatchRelated: GodDetails;
  dataPatchUnrelated: GodDetailsPatchUnrelated;
  numberOfMatchesMode: number;

  selectedMode$: BehaviorSubject<string> = new BehaviorSubject(null);
  selectedMode: string;
  selectedRank = 0;
  public godName: string;
  lore: string[];
  abilities = [];

  chartWinRate: Chart;
  chartWinRateHistory: Chart;
  chartWinRatePopularityRank: Chart;
  chartWinRateRanks: Chart;
  chartPopularityRanks: Chart;
  popularity = '-1';
  popularitySup = 'th';
  winning = '-1';
  winningSup = 'th';

  dataSkins: Skin[] = [];

  loading = true;
  loadingMode = true;
  loadingGraphs = true;
  loadingSkins = true;

  bestIn = '';
  bestInVal = -1;
  worstIn = '';
  worstInVal = 200;

  bestInRank = '';
  bestInRankMode = '';
  bestInRankVal = -1;
  worstInRank = '';
  worstInRankMode = '';
  worstInRankVal = 200;

  PP_change: GodDetailsChange = {
    'win%': '',
    winrate: '',
    popularity: '',
    kills: '',
    deaths: '',
    assists: '',
    pick: '',
    bans: '',
    pickban: '',
    penta: '',
    selfheal: '',
    heal: '',
    taken: '',
    mitigated: '',
    damage: '',
    gold: ''
  };

  patchSubscription: Subscription;
  rankSubscription: Subscription;
  subscriptions: Subscription[] = [];
  paramsSubscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private godDetailsService: GodDetailsService,
    private godsService: GodsService,
    private patchService: PatchService,
    private rankService: RankService,
    private numberMatchesService: NumberMatchesService,
    private changeDetectorRef: ChangeDetectorRef,
    private seoService: SeoService
  ) {}

  ngOnInit() {
    this.paramsSubscriptions.push(this.route.queryParamMap.subscribe(queryParams => {
      if (this.queues.includes(queryParams.get('mode'))) {
        this.queueButtonSetup(queryParams.get('mode'));
        this.selectedMode$.next(queryParams.get('mode'));
      } else {
        this.queueButtonSetup('conquest');
        this.selectedMode$.next('conquest');
      }
    }));

    this.paramsSubscriptions.push(this.route.paramMap.subscribe((params: ParamMap) => {
      this.godName = params.get('name');
      this.godsService.getGodsNames().pipe(take(1)).subscribe((gods: string) => {
        if (!gods.includes(this.godName)) {
          this.router.navigate(['/']);
        }
      });

      this.loading = true;
      this.loadingSkins = true;
      this.loadingMode = true;
      this.loadingGraphs = true;

      this.patchSubscription = this.patchService.getSelectedPatch().subscribe(sp => {
        if (sp == null) {
          return;
        }

        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
        if (this.rankSubscription) {
          this.rankSubscription.unsubscribe();
        }

        this.loading = true;
        this.loadingSkins = true;
        this.loadingMode = true;
        this.loadingGraphs = true;

        this.rankSubscription = this.rankService.getSelectedRank().subscribe((rank: number) => {
          if (rank == null) {
            return;
          }
          this.subscriptions.forEach(subscription => subscription.unsubscribe());
          this.subscriptions = [];

          this.selectedRank = rank;

          this.bestIn = '';
          this.bestInVal = -1;
          this.worstIn = '';
          this.worstInVal = 200;

          this.bestInRank = '';
          this.bestInRankVal = -1;
          this.worstInRank = '';
          this.worstInRankVal = 200;

          for (const aq of this.queues) {
            for (let i = 0; i < this.ranks.length; i++) {
              this.subscriptions.push(this.godDetailsService.getSampleSize(this.godName, sp, aq, i).subscribe((ss: number) => {
                this.subscriptions.push(this.godDetailsService.getWin(this.godName, sp, aq, i).subscribe((w: number) => {
                  const wr = parseFloat((100 * w / ss).toFixed(2));

                  // Best in all ranks all modes
                  if (wr > this.bestInRankVal) {
                    this.bestInRank = this.ranks[i];
                    this.bestInRankMode = aq;
                    this.bestInRankVal = wr;
                  }
                  if (wr < this.worstInRankVal) {
                    this.worstInRank = this.ranks[i];
                    this.worstInRankMode = aq;
                    this.worstInRankVal = wr;
                  }
                }));
              }));
            }

            this.subscriptions.push(this.godDetailsService.getSampleSize(this.godName, sp, aq, rank).subscribe((ss: number) => {
              this.subscriptions.push(this.godDetailsService.getWin(this.godName, sp, aq, rank).subscribe((w: number) => {
                const wr = parseFloat((100 * w / ss).toFixed(2));

                // Best in current rank all modes
                if (wr > this.bestInVal) {
                  this.bestIn = aq;
                  this.bestInVal = wr;
                }
                if (wr < this.worstInVal) {
                  this.worstIn = aq;
                  this.worstInVal = wr;
                }
              }));
            }));
          }

          this.subscriptions.push(this.selectedMode$.subscribe(selectedMode => {

            this.selectedMode = selectedMode;

            this.loadingMode = true;
            this.loadingGraphs = true;
            this.loadingSkins = true;

            this.mostMatchesItems = 0;
            this.mostMatchesActives = 0;
            this.mostMatchesGoodWith = 0;
            this.mostMatchesGoodAgainst = 0;

            this.subscriptions.push(this.numberMatchesService.getNumMatchesModePerRank(sp, selectedMode, rank).subscribe((numMatches: number) => {
              this.numberOfMatchesMode = numMatches;
            }));

            this.subscriptions.push(this.godDetailsService.getPatchUnrelated(this.godName).subscribe((ud: GodDetailsPatchUnrelated) => {
              if (!ud) {
                return;
              }
              this.dataPatchUnrelated = ud;

              // SEO
              this.seoService.setSeo('godDetails', this.godName);

              this.lore = this.dataPatchUnrelated.L.split('\\n\\n');
              this.abilities = [this.dataPatchUnrelated.A1,
                this.dataPatchUnrelated.A2,
                this.dataPatchUnrelated.A3,
                this.dataPatchUnrelated.A4,
                this.dataPatchUnrelated.A5];

              this.loading = false;

              this.subscriptions.push(this.godDetailsService.getPatchRelated(this.godName, sp, selectedMode, rank).subscribe((d: GodDetails) => {
                if (!d) {
                  return;
                }
                this.dataPatchRelated = d;

                // previous patch data
                this.subscriptions.push(this.patchService.getPreviousPatch(sp).subscribe((pp: string) => {
                  this.PP_change['win%'] = '';
                  this.PP_change.winrate = '';
                  this.PP_change.popularity = '';
                  this.PP_change.kills = '';
                  this.PP_change.deaths = '';
                  this.PP_change.assists = '';
                  this.PP_change.pick = '';
                  this.PP_change.bans = '';
                  this.PP_change.pickban = '';
                  this.PP_change.selfheal = '';
                  this.PP_change.heal = '';
                  this.PP_change.taken = '';
                  this.PP_change.mitigated = '';
                  this.PP_change.damage = '';
                  this.PP_change.gold = '';
                  if (!pp) {
                    return;
                  }

                  this.subscriptions.push(this.numberMatchesService.getNumMatchesModePerRank(pp, selectedMode, rank).subscribe((numMatchesPP: number) => {
                    
                    this.subscriptions.push(this.godDetailsService.getPatchRelated(this.godName, pp, selectedMode, rank).subscribe((PP_d: GodDetails) => {
                      if (!PP_d) {
                        return;
                      }
                      this.PP_change['win%'] = ((100 * d.W / d.S) - (100 * PP_d.W / PP_d.S)).toFixed(2);
                      this.PP_change.winrate = String(Number(PP_d['R%']) - Number(d['R%']));
                      this.PP_change.popularity = String(Number(PP_d.RS) - Number(d.RS));
                      this.PP_change.kills = String(Math.round(Number(d.K)) - Math.round(Number(PP_d.K)));
                      this.PP_change.deaths = String(Math.round(Number(d.D)) - Math.round(Number(PP_d.D)));
                      this.PP_change.assists = String(Math.round(Number(d.A)) - Math.round(Number(PP_d.A)));
                      this.PP_change.pick = (((d.S / this.numberOfMatchesMode) * 100) - ((PP_d.S / numMatchesPP) * 100)).toFixed(2);
                      this.PP_change.bans = (((d.B / this.numberOfMatchesMode) * 100) - ((PP_d.B / numMatchesPP) * 100)).toFixed(2);
                      this.PP_change.pickban = ((((d.B + d.S) / this.numberOfMatchesMode) * 100) -
                                               (((PP_d.B + PP_d.S) / numMatchesPP) * 100)).toFixed(2);
                      this.PP_change.penta = (Number(d.P) - Number(PP_d.P)).toFixed(3);
                      this.PP_change.selfheal = String(Number(d.HS) - Number(PP_d.HS));
                      this.PP_change.heal = String(Number(d.H) - Number(PP_d.H));
                      this.PP_change.taken = String(Number(d.T) - Number(PP_d.T));
                      this.PP_change.mitigated = String(Number(d.M) - Number(PP_d.M));
                      this.PP_change.damage = String(Number(d.DA) - Number(PP_d.DA));
                      this.PP_change.gold = String(Number(d.G) - Number(PP_d.G));

                      for (const x of Object.keys(this.PP_change)) {
                        if (isNaN(this.PP_change[x]) || Number(this.PP_change[x]) === 0) {
                          this.PP_change[x] = '';
                        }
                        if (Number(this.PP_change[x]) > 0) {
                          this.PP_change[x] = '+' + this.PP_change[x];
                        }
                      }
                    }));
                  }));
                }));

                this.winning = String(d['R%']);
                if (this.winning[this.winning.length - 1] === '1' && this.winning !== '11') {
                  this.winningSup = 'st';
                } else if (this.winning[this.winning.length - 1] === '2' && this.winning !== '12') {
                  this.winningSup = 'nd';
                } else if (this.winning[this.winning.length - 1] === '3' && this.winning !== '13') {
                  this.winningSup = 'rd';
                } else {
                  this.winningSup = 'th';
                }

                this.popularity = String(d.RS);
                if (this.popularity[this.popularity.length - 1] === '1' && this.popularity !== '11') {
                  this.popularitySup = 'st';
                } else if (this.popularity[this.popularity.length - 1] === '2' && this.popularity !== '12') {
                  this.popularitySup = 'nd';
                } else if (this.popularity[this.popularity.length - 1] === '3' && this.popularity !== '13') {
                  this.popularitySup = 'rd';
                } else {
                  this.popularitySup = 'th';
                }

                $(`#${selectedMode}_btn`).css('background', '#071325');
                $(`#${selectedMode}_btn`).css('color', '#c9d4e2');
                $(`#${selectedMode}_btn`).css('filter', 'brightness(130%)');

                // če že obstaja chart ga uniči
                if (this.chartWinRate && this.chartWinRate.length !== 0) {
                  this.chartWinRate.destroy();
                }
                setTimeout(() => {
                  this.chartWinRate = new Chart($('#' + selectedMode + '_winrate'), {
                    type: 'doughnut',
                    data: {
                        labels: ['Wins', 'Loses'],
                        datasets: [{
                            label: 'Win rate',
                            data: [this.dataPatchRelated.W, this.dataPatchRelated.S - this.dataPatchRelated.W],
                            backgroundColor: [
                                '#4bb44b',
                                '#a33434'
                            ]
                        }]
                    },
                    options: {
                      aspectRatio: 1,
                      responsive: true,
                      legend: {
                        display: false,
                      },
                      layout: {
                        padding: {
                          left: 20,
                          right: 20,
                          top: 20,
                          bottom: 20
                        }
                      },
                      cutoutPercentage: 70
                    }
                  });
                }, 1);

                this.loadingMode = false;

                this.subscriptions.push(this.patchService.getPatches().subscribe((patches: any) => {
                  const winrates = {};
                  const popularityRanks = {};
                  const winrateRanks = {};
                  const getWinCalls = [];
                  const getSampleSizeCalls = [];
                  const getPopularityRankCalls = [];
                  const getWinrateRankCalls = [];

                  patches = Object.values(patches).filter(p => p != '')
                  patches.forEach(p => {
                    getWinCalls.push(this.godDetailsService.getWin(this.godName, p, selectedMode, rank));
                    getSampleSizeCalls.push(this.godDetailsService.getSampleSize(this.godName, p, selectedMode, rank));
                    getPopularityRankCalls.push(this.godDetailsService.getPopularityRank(this.godName, p, selectedMode, rank));
                    getWinrateRankCalls.push(this.godDetailsService.getWinrateRank(this.godName, p, selectedMode, rank));
                  });

                  combineLatest(getWinCalls).pipe(take(1)).subscribe((wins: number[]) => {
                    combineLatest(getSampleSizeCalls).pipe(take(1)).subscribe((sampleSizes: number[]) => {

                      for (let i = 0; i < wins.length; i++) {
                        if (sampleSizes[i]) {
                          winrates[this.replaceStars(patches[i])] = ((wins[i] / sampleSizes[i]) * 100).toFixed(2);
                        }
                      }

                      // če že obstaja chart ga uniči
                      if (this.chartWinRateHistory && this.chartWinRateHistory.length !== 0) {
                        this.chartWinRateHistory.destroy();
                      }
                      setTimeout(() => {
                        this.chartWinRateHistory = new Chart($('#' + selectedMode + '_winrate_history'), {
                          type: 'line',
                          data: {
                            labels: Object.keys(winrates),
                            datasets: [{
                              label: 'Win rate',
                              data: Object.values(winrates),
                              backgroundColor: '#d1c08433',
                              borderColor: '#D1C084',
                              radius: 5,
                              pointHitRadius: 5
                            },
                            {
                              data: Array.apply(null, new Array(Object.keys(winrates).length)).map(Number.prototype.valueOf, 50),
                              fill: false,
                              radius: 0,
                              borderColor: '#a33434'
                            }]
                          },
                          options: {
                            maintainAspectRatio: false,
                            scales: {
                              yAxes: [{
                                  display: true,
                                  ticks: {
                                    fontColor: '#c9d4e2c3',
                                    suggestedMin: 48,
                                    suggestedMax: 52
                                  },
                                  scaleLabel: {
                                    fontColor: '#c9d4e2c3',
                                    display: true,
                                    labelString: 'Win rate [%]'
                                  }
                              }],
                              xAxes: [{
                                display: true,
                                ticks: {
                                  fontColor: '#c9d4e2c3'
                                },
                                scaleLabel: {
                                  fontColor: '#c9d4e2c3',
                                  display: true,
                                  labelString: 'Patches'
                                }
                              }]
                            },
                            legend: {
                              labels: {
                                fontColor: '#c9d4e2c3'
                              },
                              display: false
                            },
                            layout: {
                              padding: {
                                left: 20,
                                right: 20,
                                top: 20,
                                bottom: 20
                              }
                            }
                          }
                        });
                      }, 1);
                    });
                  });

                  combineLatest(getWinrateRankCalls).pipe(take(1)).subscribe(winrateRank => {
                    combineLatest(getPopularityRankCalls).pipe(take(1)).subscribe(popularityRank => {
                      for (let i = 0; i < winrateRank.length; i++) {
                        if (winrateRank[i]) {
                          winrateRanks[this.replaceStars(patches[i])] = winrateRank[i];
                          popularityRanks[this.replaceStars(patches[i])] = popularityRank[i];
                        }
                      }

                      // če že obstaja chart ga uniči
                      if (this.chartWinRatePopularityRank && this.chartWinRatePopularityRank.length !== 0) {
                        this.chartWinRatePopularityRank.destroy();
                      }
                      setTimeout(() => {
                        this.chartWinRatePopularityRank = new Chart($('#' + selectedMode + '_winrate_popularity'), {
                          type: 'line',
                          data: {
                            labels: Object.keys(winrateRanks),
                            datasets: [{
                              label: 'Win rate',
                              data: Object.values(winrateRanks),
                              backgroundColor: '#d1c08433',
                              borderColor: '#D1C084',
                              radius: 5,
                              pointHitRadius: 5,
                              fill: 'start'
                            },
                            {
                              label: 'Popularity',
                              data: Object.values(popularityRanks),
                              backgroundColor: '#c9d4e233',
                              borderColor: '#c9d4e2',
                              radius: 5,
                              pointHitRadius: 5,
                              fill: 'start'
                            }]
                          },
                          options: {
                            legend: {
                              labels: {
                                fontColor: '#c9d4e2c3'
                              }
                            },
                            maintainAspectRatio: false,
                            scales: {
                              yAxes: [{
                                display: true,
                                ticks: {
                                  fontColor: '#c9d4e2c3',
                                  beginAtZero: true,
                                  reverse: true
                                },
                                scaleLabel: {
                                  fontColor: '#c9d4e2c3',
                                  display: true,
                                  labelString: 'Position'
                                }
                              }],
                              xAxes: [{
                                display: true,
                                ticks: {
                                  fontColor: '#c9d4e2c3'
                                },
                                scaleLabel: {
                                  fontColor: '#c9d4e2c3',
                                  display: true,
                                  labelString: 'Patches'
                                }
                              }]
                            },
                            layout: {
                              padding: {
                                left: 20,
                                right: 20,
                                top: 20,
                                bottom: 20
                              }
                            }
                          }
                        });
                      }, 1);
                    });
                  });
                }));

                this.loadingGraphs = false;
                this.loadingSkins = true;

                const getWinCallsRanks = [];
                const getNumberMatchesModePerRank = [];
                const getSampleSizeCallsRanks = [];
                const getBanCallsRanks = [];
                for (let i = 0; i < this.ranks.length; i++) {
                  getWinCallsRanks.push(this.godDetailsService.getWin(this.godName, sp, selectedMode, i));
                  getNumberMatchesModePerRank.push(this.numberMatchesService.getNumMatchesModePerRank(sp, selectedMode, i));
                  getSampleSizeCallsRanks.push(this.godDetailsService.getSampleSize(this.godName, sp, selectedMode, i));
                  getBanCallsRanks.push(this.godDetailsService.getBan(this.godName, sp, selectedMode, i));
                }

                combineLatest(getWinCallsRanks).pipe(take(1)).subscribe((wins: number[]) => {
                  combineLatest(getNumberMatchesModePerRank).pipe(take(1)).subscribe((numMatches: number[]) => {
                    combineLatest(getSampleSizeCallsRanks).pipe(take(1)).subscribe((sampleSizes: number[]) => {
                      combineLatest(getBanCallsRanks).pipe(take(1)).subscribe((bans: number[]) => {

                        const winratesRanks = [];
                        const winratesColorsRanks = [
                          '#eb415b7b', '#cd7f327b', '#D3D3D37b', '#EEE8AA7b', '#e5e4e27b', '#b9f2ff7b', '#a16bc47b', '#8cedfc7b', '#EEE8AA7b', '#e5e4e27b', '#b9f2ff7b'];
                        const winratesColorsBordersRanks = [
                          '#eb415bcb', '#cd7f32cb', '#D3D3D3cb', '#EEE8AAcb', '#e5e4e2cb', '#b9f2ffcb', '#a16bc4cb', '#8cedfccb', '#FF0000aa', '#FF0000aa', '#FF0000aa']; //eb415bcb
                        for (let i = 0; i < wins.length; i++) {
                          winratesRanks.push(((wins[i] / sampleSizes[i]) * 100).toFixed(2));
                        }

                        // če že obstaja chart ga uniči
                        if (this.chartWinRateRanks && this.chartWinRateRanks.length !== 0) {
                          this.chartWinRateRanks.destroy();
                        }
                        setTimeout(() => {
                          this.chartWinRateRanks = new Chart($('#' + selectedMode + '_winrate_ranks'), {
                            type: 'bar',
                            data: {
                              labels: this.ranks,
                              datasets: [{
                                label: 'Win rate',
                                data: winratesRanks,
                                backgroundColor: winratesColorsRanks,
                                borderColor: winratesColorsBordersRanks,
                                borderWidth: 1
                              }]
                            },
                            options: {
                              maintainAspectRatio: false,
                              scales: {
                                yAxes: [{
                                  display: true,
                                  stacked: true,
                                  ticks: {
                                    fontColor: '#c9d4e2c3',
                                    beginAtZero: true,
                                    suggestedMax: 10
                                  },
                                  scaleLabel: {
                                    fontColor: '#c9d4e2c3',
                                    display: true,
                                    labelString: 'Win rate [%]'
                                  },
                                  gridLines: {
                                    display: false,
                                    color: '#c9d4e2c3'
                                  }
                                }],
                                xAxes: [{
                                  display: true,
                                  stacked: true,
                                  ticks: {
                                    fontColor: '#c9d4e2c3'
                                  },
                                  gridLines: {
                                    display: false,
                                    color: '#c9d4e2c3'
                                  }
                                }]
                              },
                              layout: {
                                padding: {
                                  left: 20,
                                  right: 20,
                                  top: 20,
                                  bottom: 20
                                }
                              }
                            }
                          });
                        }, 1);


                        const pickRate = [];
                        const banRate = [];
                        const borderColorsPick = [
                          '#EEE8AAcb', '#EEE8AAcb', '#EEE8AAcb', '#EEE8AAcb', '#EEE8AAcb', '#EEE8AAcb', '#EEE8AAcb', '#EEE8AAcb', '#FF0000aa', '#FF0000aa', '#FF0000aa'];
                        const borderColorsBan = [
                          '#e5e4e2cb', '#e5e4e2cb', '#e5e4e2cb', '#e5e4e2cb', '#e5e4e2cb', '#e5e4e2cb', '#e5e4e2cb', '#e5e4e2cb', '#FF0000aa', '#FF0000aa', '#FF0000aa'];
                        for (let i = 0; i < sampleSizes.length; i++) {
                          pickRate.push(((sampleSizes[i] / numMatches[i]) * 100).toFixed(2));
                          banRate.push(((bans[i] / numMatches[i]) * 100).toFixed(2));
                        }

                        // če že obstaja chart ga uniči
                        if (this.chartPopularityRanks && this.chartPopularityRanks.length !== 0) {
                          this.chartPopularityRanks.destroy();
                        }
                        setTimeout(() => {
                          this.chartPopularityRanks = new Chart($('#' + selectedMode + '_popularity_ranks'), {
                            type: 'bar',
                            data: {
                              labels: this.ranks,
                              datasets: [{
                                label: 'Pick rate',
                                data: pickRate,
                                backgroundColor: '#EEE8AA7b',
                                radius: 5,
                                pointHitRadius: 5,
                                fill: 'start',
                                borderColor: borderColorsPick,
                                borderWidth: 1
                              },
                              {
                                label: 'Ban rate',
                                data: banRate,
                                backgroundColor: '#e5e4e27b',
                                radius: 5,
                                pointHitRadius: 5,
                                fill: 'start',
                                borderColor: borderColorsBan,
                                borderWidth: 1
                              }]
                            },
                            options: {
                              legend: {
                                labels: {
                                  fontColor: '#c9d4e2c3'
                                }
                              },
                              maintainAspectRatio: false,
                              scales: {
                                yAxes: [{
                                  display: true,
                                  stacked: true,
                                  ticks: {
                                    fontColor: '#c9d4e2c3',
                                    beginAtZero: true
                                  },
                                  scaleLabel: {
                                    fontColor: '#c9d4e2c3',
                                    display: true,
                                    labelString: 'Rate [%]'
                                  },
                                  gridLines: {
                                    display: false,
                                    color: '#c9d4e2c3'
                                  }
                                }],
                                xAxes: [{
                                  display: true,
                                  stacked: true,
                                  ticks: {
                                    fontColor: '#c9d4e2c3'
                                  },
                                  gridLines: {
                                    display: false,
                                    color: '#c9d4e2c3'
                                  }
                                }]
                              },
                              layout: {
                                padding: {
                                  left: 20,
                                  right: 20,
                                  top: 20,
                                  bottom: 20
                                }
                              }
                            }
                          });
                        }, 1);
                      });
                    });
                  });
                });

                this.subscriptions.push(this.godDetailsService.getItemsItem(this.godName, sp, selectedMode, rank).subscribe((items: any) => {
                  let arr: Item[] = [];

                  if (items != null) {
                    for (const x of Object.keys(items)) {
                      const menuItems = [];
                      for (const menuItem of Object.keys(items[x].M)) {
                        menuItems.push([menuItem, items[x].M[menuItem]]);
                      }

                      arr.push({
                        I: items[x].I,
                        N: x,
                        S: items[x].S,
                        '%': (100 * items[x].W) / items[x].S,
                        D: items[x].D,
                        E: items[x].E,
                        M: menuItems,
                      });

                      if (items[x].S > this.mostMatchesItems) {
                        this.mostMatchesItems = items[x].S;
                      }
                    }
                  }

                  this.dataItems = new MatTableDataSource(arr);
                  this.dataItems.sort = this.itemsSort;
                  this.dataItems.paginator = this.itemsPaginator;
                  this.changeDetectorRef.detectChanges();
                }));

                this.subscriptions.push(this.godDetailsService.getItemsActive(this.godName, sp, selectedMode, rank).subscribe((actives: any) => {
                  let arr: Item[] = [];

                  if (actives != null) {
                    for (const x of Object.keys(actives)) {
                      arr.push({
                        I: actives[x].I,
                        N: x,
                        S: actives[x].S,
                        '%': (100 * actives[x].W) / actives[x].S,
                        D: actives[x].D,
                        E: actives[x].E,
                        M: []
                      });

                      if (actives[x].S > this.mostMatchesActives) {
                        this.mostMatchesActives = actives[x].S;
                      }
                    }
                  }

                  this.dataActives = new MatTableDataSource(arr);
                  this.dataActives.sort = this.activesSort;
                  this.dataActives.paginator = this.activesPaginator;
                  this.changeDetectorRef.detectChanges();
                }));

                this.subscriptions.push(this.godDetailsService.getGodsGoodWith(this.godName, sp, selectedMode, rank).subscribe((d: any) => {
                  if (d == null) {
                    return;
                  }
                  let arr: God[] = [];
                  for (const x of Object.keys(d)) {
                    arr.push({
                      I: d[x].I,
                      N: x,
                      S: d[x].S,
                      '%': d[x]['%']
                    });

                    if (d[x].S > this.mostMatchesGoodWith) {
                      this.mostMatchesGoodWith = d[x].S;
                    }
                  }

                  this.dataGoodWith = new MatTableDataSource(arr);
                  this.dataGoodWith.sort = this.goodWithSort;
                  this.dataGoodWith.paginator = this.goodWithPaginator;
                  this.changeDetectorRef.detectChanges();
                }));

                this.subscriptions.push(this.godDetailsService.getGodsGoodAgainst(this.godName, sp, selectedMode, rank).subscribe((d: any) => {
                  if (d == null) {
                    return;
                  }
                  let arr: God[] = [];
                  for (const x of Object.keys(d)) {
                    arr.push({
                      I: d[x].I,
                      N: x,
                      S: d[x].S,
                      '%': d[x]['%']
                    });

                    if (d[x].S > this.mostMatchesGoodAgainst) {
                      this.mostMatchesGoodAgainst = d[x].S;
                    }
                  }

                  this.dataGoodAgainst = new MatTableDataSource(arr);
                  this.dataGoodAgainst.sort = this.goodAgainstSort;
                  this.dataGoodAgainst.paginator = this.goodAgainstPaginator;
                  this.changeDetectorRef.detectChanges();
                }));

                this.subscriptions.push(this.godDetailsService.getSkins(this.godName, sp, selectedMode, rank).subscribe((s: any) => {
                  if (s == null) {
                    return;
                  }

                  this.dataSkins = [];
                  for (const x of Object.keys(s)) {
                    this.dataSkins.push({
                      N: x,
                      U: s[x].U,
                      '%': s[x]['%'],
                      S: s[x].S
                    });
                  }

                  this.dataSkins = this.dataSkins.sort((a, b) => b['%'] - a['%']);

                  this.loadingSkins = false;
                }));
              }));
            }));
          }));
        });
      });
    }));
  }

  ngOnDestroy() {
    if (this.patchSubscription) {
      this.patchSubscription.unsubscribe();
    }
    if (this.rankSubscription) {
      this.rankSubscription.unsubscribe();
    }
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.paramsSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onQueueSelect(q: string) {
    this.router.navigate([`gods/${this.godName}`], {queryParams: { mode: q }, replaceUrl: true});
  }

  queueButtonSetup(q: string) {
    for (const aq of this.queues) {
      if (aq !== q) {
        $(`#${aq}_btn`).css('background', 'unset');
        $(`#${aq}_btn`).css('color', '#c9d4e2');
        $(`#${aq}_btn`).css('filter', 'brightness(50%)');
      }
    }
  }

  applyFilterItems(filterValue: string) {
    this.dataItems.filter = filterValue.trim().toLowerCase();

    if (this.dataItems.paginator) {
      this.dataItems.paginator.firstPage();
    }
  }

  applyFilterActives(filterValue: string) {
    this.dataActives.filter = filterValue.trim().toLowerCase();

    if (this.dataActives.paginator) {
      this.dataActives.paginator.firstPage();
    }
  }

  applyFilterGoodWith(filterValue: string) {
    this.dataGoodWith.filter = filterValue.trim().toLowerCase();

    if (this.dataGoodWith.paginator) {
      this.dataGoodWith.paginator.firstPage();
    }
  }

  applyFilterGoodAgainst(filterValue: string) {
    this.dataGoodAgainst.filter = filterValue.trim().toLowerCase();

    if (this.dataGoodAgainst.paginator) {
      this.dataGoodAgainst.paginator.firstPage();
    }
  }

  replaceStars(s: string) {
    return s.replace(/\*/g, '.');
  }

  getColor(pct: number) {
    return pct < 50 ? '#a33434' : pct > 50 ? '#4bb44b' : '#D1C084';
  }

  getChangeColor(pct: number, reverse?: boolean) {
    if (reverse) {
      if (pct > 0) {
        return '#a33434';
      } else if (pct < 0) {
        return '#4bb44b';
      }
    }
    if (pct < 0) {
      return '#a33434';
    } else if (pct > 0) {
      return '#4bb44b';
    }
    return '#c9d4e2';
  }

  capitalize(str: string) {
    return str.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  }

  imageURLRefactor(str: string) {
    const res = str.replace(',', '').replace('\'', '').replace(/\s+/g, '-').replace(/\(([^\)]+)\)/g, '').toLowerCase();
    return res[res.length - 1] === '-' ? res.slice(0, -1) : res;
  }

  godNameURLRefactor(str: string) {
    return str.replace('\'', '').replace(/\s+/g, '_').toLowerCase();
  }

  FormatLongNumber(value: number) {
    if (value === 0) {
      return 0;
    } else if (value < 100000) {
      // hundreds
      return value;
    } else if (value >= 100000 && value < 1000000) {
      // thousands
      return (value / 1000).toFixed(0) + 'k';
    } else if (value >= 1000000 && value < 1000000000) {
      // millions
      return (value / 1000000).toFixed(3) + 'M';
    } else if (value >= 1000000000 && value < 1000000000000) {
      // billions
      return (value / 1000000000).toFixed(3) + 'B';
    }
    return value;
  }
}
