import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class TopGodsService {

  constructor(private db: AngularFireDatabase) {
  }

  getGods(mode: string, patch: string, rank: number) {
    return this.db.object(`${patch.replace(/\./g, '*')}/${rank}/${mode}/R`).valueChanges();
  }

  getCompositions(mode: string, patch: string, rank: number) {
    return this.db.object(`${patch.replace(/\./g, '*')}/${rank}/${mode}/C`).valueChanges();
  }
}
