import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class LiveService {

  constructor(private db: AngularFireDatabase, private fns: AngularFireFunctions) { }

  async getLiveData(name: string, token: string) {
    const options = {
      method: 'POST',
      body: JSON.stringify({
       data: name,
       token: token
      })
    };

    let res: {};
    await fetch('https://us-central1-rankedsmite-63ae4.cloudfunctions.net/liveStats', options)
      .then(response => {res = response.json()})
      .catch(serverErrors => {console.log("error: " + serverErrors)});
    return res;
  }

  getCompareGoodWith(name1: string, name2: string, patch: string, queue: string, exp: number) {
    return this.db.object(`${patch.replace(/\./g, '*')}/${exp}/${queue}/GW/${name1}/${name2}/%`).valueChanges();
  }

  getCompareGoodAgainst(name1: string, name2: string, patch: string, queue: string, exp: number) {
    return this.db.object(`${patch.replace(/\./g, '*')}/${exp}/${queue}/GA/${name1}/${name2}/%`).valueChanges();
  }
}
