import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class GodsService {

  constructor(private db: AngularFireDatabase) {
  }

  getGods() {
    return this.db.object(`U`).valueChanges();
  }

  getGodsNames() {
    return this.db.object('G').valueChanges();
  }

  getGodsSlideshow(patch: string, exp: number) {
    return this.db.object(`${patch.replace(/\./g, '*')}/${exp}/S`).valueChanges();
  }

}