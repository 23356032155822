import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import 'hammerjs';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { SidenavService } from './services/sidenav.service';
import { AdblockService } from './services/adblock.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild('sidenav', { static: true })
  public sidenav: MatSidenav;

  @ViewChild('sidenavContent', { static: true })
  private sidenavContent: MatSidenavContent;

  public isBlockingAds: boolean;

  constructor(
    private router: Router,
    private sidenavService: SidenavService,
    private adblockService: AdblockService,
    private snackBar: MatSnackBar
    ) {}

  async ngOnInit() {
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-140513802-1', {
        page_path: event.urlAfterRedirects
      });
    });

    this.sidenavService.setSidenav(this.sidenav);

    this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
    ).subscribe((event: RoutesRecognized) => {
      if (this.sidenavContent) {
        this.sidenavContent.scrollTo({ top: 0 });
      }
    });

    this.isBlockingAds = await this.adblockService.checkAdBlocker();
    if (this.isBlockingAds) {
      this.snackBar.open('I understand you don\'t like ads, but this website could not exist without them. Help me keep it alive by turning your AdBlock off on this domain. Thank you! ❤️', 'Got it', {
      });
    }
  }

}
