import { Component, OnInit } from '@angular/core';
import { PatchService } from '../../services/patch.service';
import { RankService } from '../../services/rank.service';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  patches = [];
  selectedPatch: string;
  selectedRank = 'All ranks';
  ranks: string[] = ['All ranks', 'Bronze', 'Silver', 'Gold', 'Gold–', 'Platinum', 'Platinum+', 'Diamond', 'Diamond+', 'Master', 'Grandmaster'];
  rankNumbers: number[] = [0, 1, 2, 3, 8, 4, 9, 5, 10, 6, 7];

  constructor(
    public patchService: PatchService,
    public rankService: RankService,
    public sidenavService: SidenavService
    ) { }

  ngOnInit() {
    this.patches = [];

    this.patchService.getPatches().subscribe((patches: string[]) => {
      if (patches == null) {
        return;
      }
      this.patches = Object.values(patches).filter(p => p != '').map(p => p.replace('*', '.'));
      this.selectedPatch = this.patches[this.patches.length - 1];
    });
  }

  changeRank() {
    this.rankService.setSelectedRank(this.rankNumbers[this.ranks.indexOf(this.selectedRank)]);
  }

  changePatch() {
    this.patchService.setSelectedPatch(this.selectedPatch.replace(/\./g, '*'));
  }
}
