import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PatchService {

  selectedPatch$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private db: AngularFireDatabase) {
    this.getLatestPatch().pipe(take(1)).subscribe((lp: string) => {
      this.selectedPatch$.next(lp);
    });
  }

  getPatches(): Observable<string[]> {
    return this.db.object<string[]>('P').valueChanges();
  }

  getLatestPatch(): Observable<string> {
    return this.db.object<string>(`L`).valueChanges();
  }

  getPreviousPatch(patch: string): Observable<string> {
    return this.db.object<string>(`${patch}/PP`).valueChanges();
  }

  getSelectedPatch() {
    return this.selectedPatch$;
  }

  setSelectedPatch(p: string) {
    this.selectedPatch$.next(p);
  }

}
