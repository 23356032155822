import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RankService {

  selectedRank$: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor() { }

  getSelectedRank() {
    return this.selectedRank$;
  }

  setSelectedRank(e: number) {
    this.selectedRank$.next(e);
  }

  getSelectedRankName(rank: number) {
    let selectedRank = 'All ranks';

    switch (rank) {
      case 1:
        selectedRank = 'Bronze';
        break;
      case 2:
        selectedRank = 'Silver';
        break;
      case 3:
        selectedRank = 'Gold';
        break;
      case 4:
        selectedRank = 'Platinum';
        break;
      case 5:
        selectedRank = 'Diamond';
        break;
      case 6:
        selectedRank = 'Master';
        break;
      case 7:
        selectedRank = 'Grandmaster';
        break;
      case 8:
        selectedRank = 'Gold–';
        break;
      case 9:
        selectedRank = 'Platinum+';
        break;
      case 10:
        selectedRank = 'Diamond+';
        break;
    }

    return selectedRank;
  }
}
