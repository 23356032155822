import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './content/home/home.component';
import { GodDetailsComponent } from './content/god-details/god-details.component';
import { GodsComponent } from './content/gods/gods.component';
import { ModeDetailsComponent } from './content/mode-details/mode-details.component';
import { AboutComponent } from './content/about/about.component';
import { LiveStatsComponent } from './content/live-stats/live-stats.component';
import { MaintenanceComponent } from './content/maintenance/maintenance.component';
import { Page404Component } from './content/page404/page404.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'mode/:name', component: ModeDetailsComponent },
  { path: 'gods', component: GodsComponent },
  { path: 'gods/:name', component: GodDetailsComponent },
  { path: 'live', component: LiveStatsComponent },
  { path: 'about', component: AboutComponent },
  //{ path: '404', component: Page404Component },
  { path: '**', redirectTo: '', pathMatch: 'full' }
  // { path: '**', component: MaintenanceComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
