import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class GodDetailsService {
  godDetails: Observable<{}>[];

  constructor(private db: AngularFireDatabase) {
  }

  getPatchRelated(name: string, patch: string, queue: string, exp: number) {
    return this.db.object(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/R/' + name).valueChanges();
  }

  getPatchUnrelated(name: string) {
    return this.db.object('U/' + name).valueChanges();
  }

  getGodRole(name: string) {
    return this.db.object(`U/${name}/R`).valueChanges();
  }

  getGodsGoodWith(name: string, patch: string, queue: string, exp: number) {
    return this.db.object(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/GW/' + name).valueChanges();
  }

  getGodsGoodAgainst(name: string, patch: string, queue: string, exp: number) {
    return this.db.object(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/GA/' + name).valueChanges();
  }

  getItemsActive(name: string, patch: string, queue: string, exp: number) {
    return this.db.object(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/A/' + name).valueChanges();
  }

  getItemsItem(name: string, patch: string, queue: string, exp: number) {
    return this.db.object(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/I/' + name).valueChanges();
  }

  getSkins(name: string, patch: string, queue: string, exp: number) {
    return this.db.object(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/SK/' + name).valueChanges();
  }

  getSampleSize(name: string, patch: string, queue: string, exp: number): Observable<number> {
    return this.db.object<number>(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/R/' + name + '/S').valueChanges();
  }

  getBan(name: string, patch: string, queue: string, exp: number): Observable<number> {
    return this.db.object<number>(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/R/' + name + '/B').valueChanges();
  }

  getWin(name: string, patch: string, queue: string, exp: number): Observable<number> {
    return this.db.object<number>(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/R/' + name + '/W').valueChanges();
  }

  getPopularityRank(name: string, patch: string, queue: string, exp: number): Observable<number> {
    return this.db.object<number>(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/R/' + name + '/RS').valueChanges();
  }

  getWinrateRank(name: string, patch: string, queue: string, exp: number): Observable<number> {
    return this.db.object<number>(patch.replace(/\./g, '*') + '/' + exp + '/' + queue + '/R/' + name + '/R%').valueChanges();
  }

}
