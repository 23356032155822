import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private sidenav: MatSidenav;
  private content = {
    menu: false,
    category: false
  };

  constructor() { }

  public getOpen$(): Observable<any> {
    return this.sidenav.openedStart;
  }

  public getContent() {
    return this.content;
  }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open() {
    this.sidenav.open();
  }

  public close(): Promise<MatDrawerToggleResult> {
    return this.sidenav.close();
  }
}
