import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { GodsService } from './gods.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
    private godsService: GodsService
  ) { }

  public setSeo(page: string, name?: string) {
    let newTitle = '';
    let newDescription = '';
    let newKeywords = '';

    this.godsService.getGodsNames().pipe(take(1)).subscribe(gods => {
      if (page === 'home') {
        newTitle = 'Ranked SMITE Stats - Casual Smite';
        newDescription = 'Latest stats for all ranked modes in SMITE: Ranked Conquest, Ranked Joust and Duel. With hourly updates and graphs. Includes stats for all ranks in SMITE: Bronze, Silver, Gold, Platinum, Diamond, Master and Grandmaster. Get recommended items to buy per mode. God stats include win rate, ban rate recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Filter matches by ranks. Sort Gods by win rate and see which Gods are current top performers in ranked SMITE.';
        newKeywords = 'smite,smite stats,casual smite,ranked smite,smite items,smite gods,conquest stats,joust stats,duel stats,statistics,stats,conquest,joust,duel,ranked,win rate,pick rate,god counters,ban rate,pickban rate,bronze,silver,gold,platinum,diamond,master,grandmaster';
      } else if (page === 'gods') {
        newTitle = 'Gods - Ranked SMITE Stats - Casual Smite';
        newDescription = 'Latest stats for all ranked modes in SMITE: Ranked Conquest, Ranked Joust and Duel. With hourly updates and graphs. Includes stats for all ranks in SMITE: Bronze, Silver, Gold, Platinum, Diamond, Master and Grandmaster. Get recommended items to buy per mode. God stats include win rate, ban rate recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Filter matches by ranks. Sort Gods by win rate and see which Gods are current top performers in ranked SMITE.';
        newKeywords = 'smite,smite stats,casual smite,ranked smite,smite items,smite gods,conquest stats,joust stats,duel stats,statistics,stats,conquest,joust,duel,ranked,win rate,pick rate,god counters,ban rate,pickban rate,bronze,silver,gold,platinum,diamond,master,grandmaster,all gods,gods,achilles,agni,ah muzen cab,ah puch,amaterasu,anhur,anubis,ao kuang,aphrodite,apollo,arachne,ares,artemis,artio,athena,awilix,baba yaga,bacchus,bakasura,baron samedi,bastet,bellona,cabrakan,camazotz,cerberus,cernunnos,chaac,chang\'e,chernobog,chiron,chronos,cthulhu,cu chulainn,cupid,da ji,discordia,erlang shen,fafnir,fenrir,freya,ganesha,geb,guan yu,hachiman,hades,he bo,heimdallr,hel,hera,hercules,horus,hou yi,hun batz,isis,izanami,janus,jing wei,jormungandr,kali,khepri,king arthur,kukulkan,kumbhakarna,kuzenbo,loki,medusa,mercury,merlin,mulan,ne zha,neith,nemesis,nike,nox,nu wa,odin,olorun,osiris,pele,persephone,poseidon,ra,raijin,rama,ratatoskr,ravana,scylla,serqet,set,skadi,sobek,sol,sun wukong,susano,sylvanus,terra,thanatos,the morrigan,thor,thoth,tyr,tsukuyomi,ullr,vamana,vulcan,xbalanque,xing tian,yemoja,ymir,zeus,zhong kui';
      } else if (page === 'godDetails') {
        newTitle = `${this.capitalize(name)} - Ranked SMITE Stats - Casual Smite`;
        newDescription = `Latest stats for ${this.capitalize(name)} in SMITE - Ranked Conquest, Ranked Joust and Duel. With hourly updates and graphs. Get recommended items to buy per mode. ${this.capitalize(name)} win rate, pick rate, ban rate, pickban rate, recommended items, counters, popularity, average kills, assists, damage...`;
        newKeywords = `smite,smite stats,ranked stats,casual smite,smite items,smite gods,arena stats,assault stats,conquest stats,` +
                      `joust stats,slash stats,statistics,stats,conquest,joust,duel,ranked,ranked conquest,ranked joust,ranked duel,` +
                      `win rate,pick rate,ban rate,pickban rate,god counters,pentakill,${this.capitalize(name)},${this.capitalize(name)} stats`;
      } else if (page === 'donate') {
        newTitle = 'Support - Ranked SMITE Stats - Casual Smite';
        newDescription = 'Support Casual Smite and help us keep it alive. Latest stats for all ranked modes in SMITE: Ranked Conquest, Ranked Joust and Duel. With hourly updates and graphs. Includes stats for all ranks in SMITE: Bronze, Silver, Gold, Platinum, Diamond, Master and Grandmaster. Get recommended items to buy per mode. God stats include win rate, ban rate recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Filter matches by ranks. Sort Gods by win rate and see which Gods are current top performers in ranked SMITE.';
        newKeywords = 'smite,smite stats,casual smite,ranked smite,smite items,smite gods,conquest stats,joust stats,duel stats,statistics,stats,conquest,joust,duel,ranked,win rate,pick rate,god counters,ban rate,pickban rate,bronze,silver,gold,platinum,diamond,master,grandmaster,support,donate';
      } else if (page === 'about') {
        newTitle = 'About - Ranked SMITE Stats - Casual Smite';
        newDescription = 'Latest stats for all ranked modes in SMITE: Ranked Conquest, Ranked Joust and Duel. With hourly updates and graphs. Includes stats for all ranks in SMITE: Bronze, Silver, Gold, Platinum, Diamond, Master and Grandmaster. Get recommended items to buy per mode. God stats include win rate, ban rate recommended items, god counters, popularity, average kills, assists, damage, gold per minute... Filter matches by ranks. Sort Gods by win rate and see which Gods are current top performers in ranked SMITE.';
        newKeywords = 'smite,smite stats,casual smite,ranked smite,smite items,smite gods,conquest stats,joust stats,duel stats,statistics,stats,conquest,joust,duel,ranked,win rate,pick rate,god counters,ban rate,pickban rate,bronze,silver,gold,platinum,diamond,master,grandmaster,about';
      } else if (page === 'live') {
        newTitle = 'Live Stats - Ranked SMITE Stats - Casual Smite';
        newDescription = 'Live stats for all ranked modes in SMITE: Ranked Conquest, Ranked Joust and Duel. Enter your in-game name and get your win chance. A win chance calculator for live matches.';
        newKeywords = 'smite,smite stats,casual smite,ranked smite,smite items,smite gods,conquest stats,joust stats,duel stats,statistics,stats,conquest,joust,duel,ranked,win rate,pick rate,god counters,ban rate,pickban rate,bronze,silver,gold,platinum,diamond,master,grandmaster,live stats,live,calculator';
      } else if (page === 'modeDetails') {
        newTitle = `${this.capitalize(name)} - Ranked SMITE Stats - Casual Smite`;
        newDescription = `Latest stats for ${this.capitalize(name)} in Smite. Includes win rate, ban rate, pickban rate, popularity, average kills, ` +
                         `assists, damage, healing... Stats for different god compositions. Sort Gods by win rate and see which Gods are current top performers.`;
        newKeywords = `smite,smite stats,normal stats,casual smite,smite items,smite gods,${name} stats,statistics,stats,${name},casual` +
                      `,win rate,god counters,compositions,smite god compositions,mage,warrior,hunter,assassin,guardian`;
      }

      newKeywords += ',' + gods;

      this.title.setTitle(newTitle);
      this.meta.updateTag({name: 'description', content: newDescription});
      this.meta.updateTag({name: 'keywords', content: newKeywords});
      this.meta.updateTag({property: 'og:title', content: newTitle});
      this.meta.updateTag({property: 'og:description', content: newDescription});
      this.meta.updateTag({property: 'twitter:title', content: newTitle});
      this.meta.updateTag({property: 'twitter:description', content: newDescription});
    });
  }

  capitalize(str: string) {
    return str.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  }
}
