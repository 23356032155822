// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBNiOck4FSuCID6zTMGFNE0qJ6ChuN6Ajk",
    authDomain: "rankedsmite-63ae4.firebaseapp.com",
    databaseURL: "https://rankedsmite-63ae4.firebaseio.com",
    projectId: "rankedsmite-63ae4",
    storageBucket: "rankedsmite-63ae4.appspot.com",
    messagingSenderId: "176095589714",
    appId: "1:176095589714:web:156f635cdcab9800bf368b",
    measurementId: "G-4Z0H30N309"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
